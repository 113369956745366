import useJwt from '@/auth/jwt/useJwt'

export default class DashboardService {
  // eslint-disable-next-line class-methods-use-this
  workedCards(type, id, from, to) {
    const data = {
      manager_id: id ?? '',
      from: from ?? '',
      to: to ?? '',
    }

    return useJwt.get(`/dashboard/v2/worked-cards/${type}`, data)
  }

  // eslint-disable-next-line class-methods-use-this
  welcome(type, id) {
    return useJwt.get(`/dashboard/v2/welcome/${type}/${id}`)
  }

  // eslint-disable-next-line class-methods-use-this
  kpis(type, id, from, to) {
    const data = {
      manager_id: id ?? '',
      from: from ?? '',
      to: to ?? '',
    }

    return useJwt.get(`/dashboard/v2/kpis/${type}`, data)
  }

  // eslint-disable-next-line class-methods-use-this
  metrics(role, id, from, to, type, users) {
    const data = {
      manager_id: id ?? '',
      from: from ?? '',
      to: to ?? '',
      type: type ?? '',
      users: users ?? '',
    }

    return useJwt.get(`/dashboard/v2/metrics/${role}`, data)
  }

  // eslint-disable-next-line class-methods-use-this
  metricsForGraphic(role, id, from, to, type) {
    const data = {
      manager_id: id ?? '',
      from: from ?? '',
      to: to ?? '',
      type: type ?? '',
    }

    return useJwt.get(`/dashboard/v2/metrics-graphic/${role}`, data)
  }

  // eslint-disable-next-line class-methods-use-this
  goalsV2(type, id, date) {
    const data = {
      manager_id: id ?? '',
      date: date ?? '',
    }

    return useJwt.get(`/dashboard/v2/goals/${type}`, data)
  }

  // eslint-disable-next-line class-methods-use-this
  downloadReport(accountManagerId, role) {
    return useJwt.download(`/dashboard/v2/pdf/${role}/${accountManagerId}`)
  }

  // eslint-disable-next-line class-methods-use-this
  statistics(type, id) {
    if (id !== null) {
      return useJwt.get(`/dashboard/statistics/${type}/${id}`)
    }

    return useJwt.get(`/dashboard/statistics/${type}`)
  }

  // eslint-disable-next-line class-methods-use-this
  graphicStatistics(type, id) {
    if (id !== null) {
      return useJwt.get(`/dashboard/graphic-statistics/${type}/${id}`)
    }

    return useJwt.get(`/dashboard/graphic-statistics/${type}`)
  }

  // eslint-disable-next-line class-methods-use-this
  goals(type, id) {
    if (id !== null) {
      return useJwt.get(`/dashboard/goals/${type}/${id}`)
    }

    return useJwt.get(`/dashboard/goals/${type}`)
  }
}
