<!-- eslint-disable vue/no-v-html -->
<template>
  <section id="dashboard">
    <b-row class="mb-1">
      <b-col>
        <b-breadcrumb
          :items="breadcrumb"
        />
      </b-col>

      <b-col
        cols="2"
        class="text-right"
      >
        <b-button
          v-if="permissionType.id !== null && permissionType.role !== null"
          size="sm"
          variant="primary"
          :disabled="isDownloading"
          @click="downloadReport"
        >
          <feather-icon
            v-if="!isDownloading"
            icon="DownloadIcon"
            size="12"
          />
          <b-spinner
            v-else
            small
          />
          Baixar relatório
        </b-button>
      </b-col>

      <b-col
        cols="4"
      >
        <b-form-select
          v-model="permissionType"
          class="form-select"
          size="sm"
          :options="roles"
          @change="changeUser"
        />
      </b-col>
    </b-row>

    <!-- kpi - retirado temporariamente -->
    <b-row
      v-if="permissionType.role === 'success' && false"
      class="match-height"
    >
      <b-col
        cols="12"
      >
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              KPI's
              <small
                v-if="permissionType.role === 'success'"
                class="font-small-1"
              >beta</small>
            </b-card-title>

            <b-card-text>
              <b-button
                v-b-tooltip.hover
                variant="flat-secondary"
                class="btn-icon"
                :title="tooltipText"
              >
                <feather-icon
                  icon="InfoIcon"
                  size="16"
                />
              </b-button>
            </b-card-text>
          </b-card-header>

          <b-row
            v-if="isLoadingKPIs"
            class="mb-5"
          >
            <b-col>
              <div class="text-center">
                <b-spinner /><br>
                <span>Carregando...</span>
              </div>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col
              v-for="(item, index) in KPIsData"
              :key="index"
              :cols="3"
            >
              <statistic-card-vertical
                :color="getValueOfMetricFilters(index, 'color')"
                :icon="getValueOfMetricFilters(index, 'icon')"
                :iconify="getValueOfMetricFilters(index, 'iconify', null)"
                :statistic="formatValue(item, getValueOfMetricFilters(index, 'decimalFormat'))"
                :statistic-title="getValueOfMetricFilters(index, 'text')"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- metrics -->
    <b-row
      class="match-height"
    >
      <b-col
        cols="12"
      >
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              Métricas
              <small
                v-if="permissionType.role === 'success'"
                class="font-small-1"
              >beta</small>
            </b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0">

              <b-row>
                <b-col
                  v-if="showUserTypeInMetrics()"
                  cols="2"
                >
                  <b-form-select
                    v-model="selectedType"
                    class="form-select"
                    :options="filtersType"
                    @change="getMetrics"
                  />
                </b-col>
                <b-col
                  :cols="showUserTypeInMetrics() ? 2 : 3"
                >
                  <flat-pickr
                    id="date_picker_metrics"
                    ref="datePicker"
                    v-model="metricsPeriod"
                    class="form-control"
                    :config="pickrConfig"
                    @on-close="onCloseCalendar(getMetrics)"
                  />
                </b-col>

                <b-col
                  :cols="showUserTypeInMetrics() ? 8 : 9"
                >
                  <b-button
                    size="sm"
                    class="mr-1"
                    variant="relief-primary"
                    :pressed="metricsPressed == 'lastMonth'"
                    @click="onClickPeriodMetrics('lastMonth')"
                  >
                    Mês anterior
                  </b-button>

                  <b-button
                    size="sm"
                    class="mr-1"
                    variant="relief-primary"
                    :pressed="metricsPressed == 'currentMonth'"
                    @click="onClickPeriodMetrics('currentMonth')"
                  >
                    Mês corrente
                  </b-button>

                  <b-button
                    size="sm"
                    class="mr-1"
                    variant="relief-primary"
                    :pressed="metricsPressed == 'month'"
                    @click="onClickPeriodMetrics('month')"
                  >
                    -30 dias
                  </b-button>

                  <b-button
                    size="sm"
                    class="mr-1"
                    variant="relief-primary"
                    :pressed="metricsPressed == 'week'"
                    @click="onClickPeriodMetrics('week')"
                  >
                    -7 dias
                  </b-button>

                  <b-button
                    size="sm"
                    class="mr-1"
                    variant="relief-primary"
                    :pressed="metricsPressed == 'ontem'"
                    @click="onClickPeriodMetrics('yesterday')"
                  >
                    Ontem
                  </b-button>

                  <b-button
                    size="sm"
                    variant="relief-primary"
                    :pressed="metricsPressed == 'day'"
                    @click="onClickPeriodMetrics('day')"
                  >
                    Hoje
                  </b-button>

                  <b-button
                    v-if="showUserTypeInMetrics()"
                    size="sm ml-1"
                    variant="relief-primary"
                    :pressed="metricsPressed == 'users'"
                    @click="metricsWithUsersList()"
                  >
                    Usuários
                    <feather-icon
                      v-if="metricsByUsers !== ''"
                      icon="CheckIcon"
                      size="13"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card-header>

          <b-row
            v-if="isLoadingMetrics"
            class="mb-5"
          >
            <b-col>
              <div class="text-center">
                <b-spinner /><br>
                <span>Carregando...</span>
              </div>
            </b-col>
          </b-row>

          <b-row v-else>
            <b-col
              v-for="(item, index) in metricsData"
              :key="index"
              :cols="colsMetrics()"
              class="justify-content-between"
              :style="`${colsMetrics() === 2 ? 'max-width:20%; min-width:20%' : ''}`"
            >
              <b-link
                v-if="getValueOfMetricFilters(index, 'value') === 'revenue' && permissionType.role === 'sales'"
                :to="{
                  name: 'cms-financial',
                  params: {
                    managerIdFromProps: permissionType.id,
                    ...metricsPeriod.includes(' até ') ? {
                      paymentDateFrom: metricsPeriod.split(' até ')[0].substring(0, 10).split('-').reverse().join('/'),
                      paymentDateTo: metricsPeriod.split(' até ')[1].substring(0, 10).split('-').reverse().join('/'),
                    } : {
                      paymentDateFrom: metricsPeriod.substring(0, 10).split('-').reverse().join('/'),
                      paymentDateTo: metricsPeriod.substring(0, 10).split('-').reverse().join('/'),
                    },
                  },
                }"
              >
                <statistic-card-vertical
                  :color="getValueOfMetricFilters(index, 'color')"
                  :icon="getValueOfMetricFilters(index, 'icon')"
                  :iconify="getValueOfMetricFilters(index, 'iconify', null)"
                  :statistic="formatValue(item, getValueOfMetricFilters(index, 'decimalFormat'))"
                  :statistic-title="getValueOfMetricFilters(index, 'text')"
                />
              </b-link>
              <b-link
                v-else-if="getValueOfMetricFilters(index, 'value') === 'health_clients'"
                :to="{
                  name: 'cms-health-score',
                  params: {
                    managerIdFromProps: permissionType.id,
                  },
                }"
              >
                <statistic-card-vertical
                  :color="getValueOfMetricFilters(index, 'color')"
                  :icon="getValueOfMetricFilters(index, 'icon')"
                  :iconify="getValueOfMetricFilters(index, 'iconify', null)"
                  :statistic="formatValue(item, getValueOfMetricFilters(index, 'decimalFormat'))"
                  :statistic-title="getValueOfMetricFilters(index, 'text')"
                />
              </b-link>
              <statistic-card-vertical
                v-else
                :color="getValueOfMetricFilters(index, 'color')"
                :icon="getValueOfMetricFilters(index, 'icon')"
                :iconify="getValueOfMetricFilters(index, 'iconify', null)"
                :statistic="formatValue(item, getValueOfMetricFilters(index, 'decimalFormat'))"
                :statistic-title="getValueOfMetricFilters(index, 'text')"
                :tooltip-text="metricsDataToolTip[`${index}_tooltip`] ? metricsDataToolTip[`${index}_tooltip`] : getValueOfMetricFilters(index, 'tooltip')"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- goals -->
    <b-row
      v-if="!['root', 'management_processes'].includes(permissionType.role)"
      class="match-height"
    >
      <b-col
        cols="12"
      >
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              Metas
              <small
                v-if="permissionType.role === 'success'"
                class="font-small-1"
              >beta</small>
            </b-card-title>

            <b-card-text class="font-small-2 mr-25 mb-0">
              <b-form-select
                v-model="monthSelected"
                class="form-select"
                size="sm"
                :options="listMonths"
                @change="getGoals"
              />
            </b-card-text>
          </b-card-header>

          <b-row
            v-if="isLoadingGoals"
            class="mb-5"
          >
            <b-col>
              <div class="text-center">
                <b-spinner /><br>
                <span>Carregando...</span>
              </div>
            </b-col>
          </b-row>

          <b-row
            v-else
            class="justify-content-around mb-2"
          >
            <b-col
              v-for="(goal, index) in goalsData"
              :key="index"
              :cols="Object.entries(goalsData).length <= 4 ? 3 : 4"
              :class="{ 'mb-4': Object.entries(goalsData).length > 4 }"
            >
              <dashboard-goal
                :title="goalsConfig[permissionType.role][index] ? goalsConfig[permissionType.role][index].value : ''"
                :decimal-format="goalsConfig[permissionType.role][index] ? goalsConfig[permissionType.role][index].decimalFormat : ''"
                :goal="goal.goal"
                :completed="goal.reached"
                :debug="goal.debug ? goal.debug : null"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="permissionType.role === 'management_processes' && daysWithoutRotine > 0">
      <b-col>
        <b-card
          border-variant="success"
          class="shadow-none"
        >
          <h4 class="mb-0">
            Estamos há {{ daysWithoutRotine }} dias sem executar tarefas rotineiras!
          </h4>
        </b-card>
      </b-col>
    </b-row>

    <!-- chart metrics -->
    <b-row>
      <b-col>

        <b-card no-body>
          <b-card-header>
            <b-card-title>
              <span @contextmenu.prevent="showWelcomeModal">Métricas detalhadas </span>
              <small
                v-if="permissionType.role === 'success'"
                class="font-small-1"
              >beta</small>
            </b-card-title>

            <b-card-text class="font-small-2 mr-25 mb-0">
              <b-row>
                <b-col v-if="userData.permission_type === 'root' && permissionType.role === 'root'">
                  <b-form-select
                    v-model="selectedType"
                    class="form-select"
                    :options="filtersType"
                    @change="getGraphics"
                  />
                </b-col>
                <b-col v-if="userData.permission_type === 'root'">
                  <flat-pickr
                    id="date_picker_graphics"
                    ref="datePicker"
                    v-model="graphicsPeriod"
                    class="form-control"
                    :config="pickrConfig"
                    @on-close="onCloseCalendar(getGraphics)"
                  />
                </b-col>
              </b-row>
            </b-card-text>
          </b-card-header>

          <b-card-body>
            <b-row>
              <b-col
                v-if="isLoadingGraphics"
                cols="9"
                class="mt-2"
              >
                <div class="text-center">
                  <b-spinner /><br>
                  <span>Carregando...</span>
                </div>
              </b-col>

              <b-col
                v-if="!showGraphics"
                class="text-center"
                cols="12"
              >
                <b-button
                  @click="getGraphics"
                >
                  Carregar gráfico
                </b-button>
              </b-col>

              <b-col
                v-if="showGraphics && !isLoadingGraphics"
                cols="9"
              >
                <vue-apex-charts
                  id="revenue-report-chart"
                  ref="revenue_report_chart"
                  type="bar"
                  :height="permissionType.role === 'root' ? 400 : 230"
                  :options="revenueReport.chartOptions"
                  :series="data.revenueReport.series"
                />
              </b-col>

              <b-col
                v-if="showGraphics"
                cols="3"
              >
                <label>Métrica: </label>
                <b-form-select
                  v-model="selectedGraphic"
                  class="form-select"
                  size="sm"
                  :options="filtersGraphic"
                  @change="loadGraphicFromSelectedMetric"
                />

                <p
                  v-if="permissionType.role === 'root'"
                  class="mt-1"
                >
                  <small v-if="selectedGraphic === 'worked_cards'">
                    Cards movimentados no kanban
                  </small>

                  <small v-if="selectedGraphic === 'unique_access'">
                    Total de acessos, somando 1 por usuário
                  </small>

                  <small v-if="selectedGraphic === 'unique_daily_access'">
                    Total de acessos, somando 1 por usuário por dia
                  </small>

                  <small v-if="selectedGraphic === 'total_access'">
                    Total de acessos
                  </small>

                  <small v-if="selectedGraphic === 'unique_searches'">
                    Total de buscas, somando 1 para cada usuário
                  </small>

                  <small v-if="selectedGraphic === 'unique_daily_searches'">
                    Total de buscas, somando 1 por usuário por dia
                  </small>

                  <small v-if="selectedGraphic === 'total_searches'">
                    Total de buscas
                  </small>

                  <small v-if="selectedGraphic === 'unique_messages'">
                    Total de mensagens, somando 1 por (Remetente + Destinatário)
                  </small>

                  <small v-if="selectedGraphic === 'total_messages'">
                    Total de mensagens, somando 1 para cada mensagem enviada
                  </small>

                  <small v-if="selectedGraphic === 'active_users'">
                    Usuários que enviaram pelo menos uma mensagem entre os 10 primeiros dias após sua aprovação
                  </small>

                  <small v-if="selectedGraphic === 'completed_registration'">
                    Total de cadastros concluídos (data do cadastro básico)
                  </small>

                  <small v-if="selectedGraphic === 'approved_registration'">
                    Total de cadastros aprovados (data da aprovação)
                  </small>

                  <small v-if="selectedGraphic === 'by_month_percent_active_bots'">
                    Bots de resposta ativos / usuários ativos
                  </small>

                  <small v-if="selectedGraphic === 'by_month_percent_readed_messages'">
                    % Mensagens lidas dentro de 10 dias (recebidas entre dia 10 e 20 de cada mês)
                  </small>

                  <small v-if="selectedGraphic === 'by_month_qtd_recurrent_users'">
                    Usuários recorrentes
                  </small>

                  <small v-if="selectedGraphic === 'by_month_percent_recurrent_users'">
                    Usuários recorrentes / total de usuários com plano ativo(pago) e status ativo
                  </small>
                </p>
              </b-col>

            </b-row>
          </b-card-body>

        </b-card>

      </b-col>
    </b-row>

    <dashboard-welcome
      v-if="userData.id && graphicsData.open_profile"
      ref="dashboardWelcome"
      :permission-type="permissionType"
      :user-data="userData"
    />

  </section>
</template>

<script>

/* eslint-disable global-require */
import {
  BBreadcrumb,
  BFormSelect,
  BButton,
  BLink,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BSpinner,
  BCol,
  VBTooltip,
} from 'bootstrap-vue'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import { userRoles, months } from '@/utils/options'
import UserService from '@/services/userService'
import DashboardService from '@/services/dashboardService'
import SettingService from '@/services/settingService'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import LocalStorageService from '@/services/localStorageService'
import downloadFile from '@/mixins/downloadFile'
import broom20Filled from '@iconify/icons-fluent/broom-20-filled'
import DashboardGoal from './DashboardGoal.vue'
import DashboardWelcome from './DashboardWelcome.vue'

export default {
  components: {
    VueApexCharts,
    DashboardGoal,
    DashboardWelcome,
    BBreadcrumb,
    BFormSelect,
    BButton,
    BLink,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BSpinner,
    BCol,
    StatisticCardVertical,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [
    downloadFile,
  ],
  data() {
    const commonMetricFilters = [
      {
        value: 'daily_confirmed_emails',
        text: 'E-mails confirmados',
        icon: 'EyeIcon',
        color: 'info',
        graphic: true,
        decimalFormat: 'decimal',
      },
      {
        value: 'daily_confirmed_phones',
        text: 'Telefones confirmados',
        icon: 'EyeIcon',
        color: 'info',
        graphic: true,
        decimalFormat: 'decimal',
      },
    ]

    return {
      daysWithoutRotine: 0,
      isDownloading: false,
      isLoadingKPIs: false,
      isLoadingMetrics: false,
      isLoadingGoals: false,
      isLoadingGraphics: false,
      showGraphics: false,
      localStorageService: null,
      userService: null,
      tooltipText: 'Health Score, Tamanho da Carteira, Usuários Recorrentes: Calculado com dados da semana passada. % Churn médio: Calculado com dados do mês passado',
      dashboardService: null,
      settingService: null,
      selectedType: 'none',
      metricsByUsers: '',
      filtersType: [
        {
          value: 'none',
          text: 'Todos',
        },
        {
          value: 'representative',
          text: 'Representante',
        },
        {
          value: 'company',
          text: 'Empresa',
        },
      ],
      filtersGraphic: [],
      metricsPressed: 'currentMonth',
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Dashboard V2',
          active: true,
        },
      ],
      pickrConfig: {
        mode: 'range',
        // noCalendar: true,
        // inline: true,
        allowInput: false,
        position: 'auto center',
        // enableTime: true,
        dateFormat: 'Y-m-d H:i:s',
        altFormat: 'd/m',
        altInput: true,
        // minDate: new Date().setMinutes(Math.floor((new Date()).getMinutes() / 5) * 5),
        time_24hr: true,
        locale: Portuguese,
      },
      metricsPeriod: '2022-05-03 00:00:0 até 2022-05-06 00:00:0',
      goalsData: [],
      icons: {
        broom20Filled,
      },
      goalsConfig: {
        success: {
          score: {
            value: 'Medida de Impacto',
            decimalFormat: 'decimal',
          },
          churn: {
            value: '% Churn',
            decimalFormat: 'decimal',
          },
          valuation: {
            value: 'Avaliação',
            decimalFormat: 'decimal',
          },
          valuation_over_clients: {
            value: '% Avaliações (Avaliações / Carteira)',
            decimalFormat: 'decimal',
          },

          // antigo relationship
          completed_onboarding: {
            value: '% Tours realizados',
            decimalFormat: 'percent.decimal',
          },
        },
        relationship: {
        },
        mentoring: {
          score: {
            value: 'Medida de Impacto',
            decimalFormat: 'decimal',
          },
          churn: {
            value: 'Churn',
            decimalFormat: 'decimal',
          },
          qualified_mentoring: {
            value: '% Qualificados de mentoria',
            decimalFormat: 'percent',
          },
          valuation_answered_percentage: {
            value: 'Avaliação automatica',
            decimalFormat: 'percent',
          },
        },
        service_qualification: {
          score: {
            value: 'Medida de Impacto',
            decimalFormat: 'decimal',
          },
          valuation: {
            value: 'Avaliação',
            decimalFormat: 'decimal',
          },
          valuation_answered_percentage: {
            value: '% Avaliação',
            decimalFormat: 'percent',
          },
          completed_onboarding: {
            value: '% Tours realizados',
            decimalFormat: 'percent.decimal',
          },
        },
        management_processes: {
          closed_tickets: {
            value: 'Tickets encerrados',
            decimalFormat: 'decimal',
          },
          filtered_users: {
            value: 'Filtros feitos',
            decimalFormat: 'decimal',
          },
          sent_email: {
            value: 'E-mails enviados',
            decimalFormat: 'decimal',
          },
        },
        sales: {
          revenue: {
            value: 'Receita Gerada',
            decimalFormat: 'currency',
          },
          average_ticket: {
            value: 'Ticket Médio',
            decimalFormat: 'currency',
          },
          score: {
            value: 'Medida de Impacto',
            decimalFormat: 'decimal',
          },
        },
      },
      metricsData: [],
      metricsDataToolTip: [],
      KPIsData: [],
      metricsFilters: {
        root: [
          {
            value: 'revenue',
            text: 'Receita',
            icon: 'EyeIcon',
            color: 'info',
            graphic: false,
            decimalFormat: 'currency',
          },
          {
            value: 'churn',
            text: 'Churn',
            icon: 'EyeIcon',
            color: 'info',
            graphic: false,
            decimalFormat: 'string',
          },
          {
            value: 'paying',
            text: 'Pagantes',
            icon: 'EyeIcon',
            color: 'info',
            graphic: false,
            decimalFormat: 'decimal',
          },
          {
            value: 'first_payment_between_0_30',
            text: 'Primeiro pagamento em 30 dias',
            icon: 'EyeIcon',
            color: 'info',
            graphic: false,
            decimalFormat: 'decimal',
          },
          {
            value: 'first_payment_between_30_90',
            text: 'Primeiro pagamento em 90 dias',
            icon: 'EyeIcon',
            color: 'info',
            graphic: false,
            decimalFormat: 'decimal',
          },
          {
            value: 'first_payment_between_90_360',
            text: 'Primeiro pagamento em 360 dias',
            icon: 'EyeIcon',
            color: 'info',
            graphic: false,
            decimalFormat: 'decimal',
          },
          {
            value: 'average_ticket',
            text: 'Ticket Médio',
            icon: 'EyeIcon',
            color: 'info',
            graphic: false,
            decimalFormat: 'currency',
          },
          {
            value: 'ltv_over_cac',
            text: 'Relação LTV/CAC',
            icon: 'EyeIcon',
            color: 'info',
            graphic: false,
            decimalFormat: 'decimal',
          },
          {
            value: 'life_time_value',
            text: 'Life Time Value (LTV)',
            icon: 'EyeIcon',
            color: 'info',
            graphic: false,
            decimalFormat: 'decimal',
          },
          {
            value: 'payback',
            text: 'Payback',
            icon: 'EyeIcon',
            color: 'info',
            graphic: false,
            decimalFormat: 'decimal',
          },
          {
            value: 'unique_access',
            text: 'Acessos Únicos',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'unique_daily_access',
            text: 'Acessos Únicos (Diário)',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'total_access',
            text: 'Acessos Totais',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'unique_searches',
            text: 'Buscas Únicas',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'unique_daily_searches',
            text: 'Buscas Únicas (Diário)',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'total_searches',
            text: 'Buscas Totais',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'unique_messages',
            text: 'Conexões Únicas',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'total_messages',
            text: 'Conexões Totais',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'active_users',
            text: 'Usuários Ativos',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'completed_registration',
            text: 'Cadastros Feitos',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'approved_registration',
            text: 'Cadastros Aprovados',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: '-',
            text: '-',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'by_month_percent_active_bots',
            text: 'Bots de resposta ativados',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'by_month_percent_readed_messages',
            text: '% Mensagens lidas 10 em 70',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'by_month_qtd_recurrent_users',
            text: 'Qtd. Usuários recorrentes',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'by_month_percent_recurrent_users',
            text: '% Usuários recorrentes',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          ...commonMetricFilters,
        ],
        sales: [
          {
            value: 'worked_cards',
            text: 'Cards Movimentados',
            icon: 'CheckSquareIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'open_profile',
            text: 'Cadastros Abertos',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'save_profile',
            text: 'Cadastro Atualizados',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'annotations',
            text: 'Anotações Criadas',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'sent_payment_link',
            text: 'Links de Pagamento Enviados',
            icon: 'SendIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'revenue',
            text: 'Receita Gerada',
            icon: 'DollarSignIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'currency',
          },
          {
            value: 'average_ticket',
            text: 'Ticket Médio',
            icon: 'ShoppingCartIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'currency',
          },
          {
            value: 'sent_email',
            text: 'E-mails Enviados',
            icon: 'MailIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          ...commonMetricFilters,
        ],
        service_qualification: [
          {
            value: 'worked_cards',
            text: 'Cards Movimentados',
            icon: 'CheckSquareIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'open_profile',
            text: 'Cadastros Abertos',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'save_profile',
            text: 'Cadastro Atualizados',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'annotations',
            text: 'Anotações Criadas',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'finished_chats',
            text: 'Atendimento',
            icon: 'MessageSquareIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'registration_approved',
            text: 'Qualificações',
            icon: 'ThumbsUpIcon',
            color: 'warning',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'qualification_lifetime',
            text: 'Tempo em Qualificação',
            icon: 'RotateCwIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'string',
          },
          {
            value: 'sanitized_cards',
            text: 'Higienizações',
            tooltip: 'Consegui contato(Contato + Telefone errado) / Todos (Contato + Telefone errado + Finalizados sem contato)',
            iconify: broom20Filled,
            color: 'info',
            graphic: true,
            decimalFormat: 'string',
          },
          {
            value: 'review',
            text: 'Cadastros Revisados',
            icon: 'ThumbsUpIcon',
            color: 'warning',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'active_review',
            text: 'Cadastros Ativos Ajustados',
            icon: 'ThumbsUpIcon',
            color: 'warning',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'valuation',
            text: 'Avaliação do Consultor',
            icon: 'StarIcon',
            color: 'danger',
            graphic: false,
            decimalFormat: 'decimal',
          },
          {
            value: 'sent_email',
            text: 'E-mails enviados',
            icon: 'MailIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'string',
          },
          {
            value: 'completed_onboarding',
            text: 'Tours Guiados realizados',
            icon: 'CheckSquareIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'completed_onboarding_percent',
            text: ' % Tours realizados',
            icon: 'ThumbsUpIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'vip_presentation',
            text: 'Apresentação Canal VIP',
            icon: 'ThumbsUpIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'score',
            text: 'Medida de Impacto',
            icon: 'ThumbsUpIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          ...commonMetricFilters,
        ],
        management_processes: [
          {
            value: 'closed_tickets',
            text: 'Tickets encerrados',
            icon: 'BellIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'string',
          },
          {
            value: 'filtered_users',
            text: 'Usuários filtrados',
            icon: 'FilterIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'string',
          },
          {
            value: 'sent_email',
            text: 'E-mails enviados',
            icon: 'MailIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'string',
          },
          ...commonMetricFilters,
        ],
        relationship: [
          ...commonMetricFilters,
        ],
        success: [
          {
            value: 'worked_cards',
            text: 'Cards Movimentados',
            icon: 'CheckSquareIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'open_profile',
            text: 'Cadastros Abertos',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'save_profile',
            text: 'Cadastro Atualizados',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'annotations',
            text: 'Anotações Criadas',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'health_clients',
            text: 'Health Score Médio Carteira',
            icon: 'ActivityIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'total_clients',
            text: 'Tamanho Carteira',
            icon: 'UserIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'recurrences',
            text: '% Usuários recorrentes (Recorrência / Carteira)',
            icon: 'RepeatIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'percent',
          },
          {
            value: 'churn',
            text: '% Churn médio Carteira',
            icon: 'AlertTriangleIcon',
            color: 'info',
            graphic: false,
            decimalFormat: 'percent',
          },
          {
            value: 'sent_email',
            text: 'E-mails Enviados',
            icon: 'MailIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'valuation',
            text: 'Avaliações',
            icon: 'CheckSquareIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'valuation_over_clients',
            text: '% Avaliaões (Avaliações / Carteira)',
            icon: 'CheckSquareIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },

          // antigo relationship
          {
            value: 'completed_onboarding',
            text: 'Tours Guiados realizados',
            icon: 'CheckSquareIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'completed_onboarding_percent',
            text: ' % Tours realizados',
            icon: 'ThumbsUpIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'score',
            text: 'Medida de Impacto',
            icon: 'FlagIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          ...commonMetricFilters,
        ],
        mentoring: [
          {
            value: 'worked_cards',
            text: 'Cards Movimentados',
            icon: 'CheckSquareIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'open_profile',
            text: 'Cadastros Abertos',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'save_profile',
            text: 'Cadastro Atualizados',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'annotations',
            text: 'Anotações Criadas',
            icon: 'EyeIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'health_clients',
            text: 'Health Score Médio Carteira',
            icon: 'ActivityIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'total_clients',
            text: 'Tamanho Carteira',
            icon: 'UserIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'recurrences',
            text: '% Usuários recorrentes (Recorrência / Carteira)',
            icon: 'RepeatIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'percent',
          },
          {
            value: 'churn',
            text: '% Churn médio Carteira',
            icon: 'AlertTriangleIcon',
            color: 'info',
            graphic: false,
            decimalFormat: 'percent',
          },
          {
            value: 'sent_email',
            text: 'E-mails Enviados',
            icon: 'MailIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'valuation',
            text: 'Avaliações',
            icon: 'CheckSquareIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'valuation_over_clients',
            text: '% Avaliaões (Avaliações / Carteira)',
            icon: 'CheckSquareIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },

          {
            value: 'meet',
            text: 'Reunião alinhamento realizados',
            icon: 'CheckSquareIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'meet_percent',
            text: '% Reunião alinhamento realizados',
            icon: 'CheckSquareIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'qualified_mentoring',
            text: '% Qualificados de mentoria',
            icon: 'CheckSquareIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          {
            value: 'score',
            text: 'Medida de Impacto',
            icon: 'FlagIcon',
            color: 'info',
            graphic: true,
            decimalFormat: 'decimal',
          },
          ...commonMetricFilters,
        ],
      },
      graphicsData: {
        paid_plans: [],
        worked_cards: [],
        open_profile: [],
        email_sent: [],
        sent_payment_link: [],
        sent_email: [],
        save_profile: [],
        revenue: [],
        average_ticket: [],
        annotations: [],
        finished_chats: [],
        registration_approved: [],
        daily_confirmed_emails: [],
        daily_confirmed_phones: [],
      },
      selectedGraphic: null,
      data: {
        revenueReport: {
          series: [
            {
              name: 'Total',
              data: [1, 2, 3, 4, 5],
            },
          ],
        },
      },
      revenueReport: {
        chartOptions: {
          chart: {
            stacked: true,
            type: 'bar',
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: true },
            },
          },
          xaxis: {
            categories: ['01/05', '02/05', '03/05', '04/05', '05/05'],
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
              colors: ['#6E6B7B'],
            },
            offsetX: 25,
          },
          colors: [$themeColors.primary, $themeColors.warning],
          plotOptions: {
            bar: {
              horizontal: true,
              columnWidth: '17%',
              endingShape: 'rounded',
              dataLabels: {
                position: 'top',
              },
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
          },
        },
      },
      userData: {
      },
      permissionType: {
        role: '',
        id: null,
      },

      KPIsPeriod: '',
      graphicsPeriod: '2022-05-03 00:00:0 até 2022-05-06 00:00:0',
      roles: [],
      monthSelected: null,
      months,
    }
  },
  computed: {
    listMonths() {
      const dt = new Date()
      dt.setDate(1)
      const list = []
      while (list.length < 13) {
        const month = dt.getMonth() + 1
        const monthString = months.find(item => item.value === month).text
        list.unshift({
          value: `${dt.getFullYear()}-${month < 10 ? `0${month}` : month}-01`,
          text: `${dt.getFullYear()} - ${monthString}`,
        })
        const nextMonth = dt.getMonth() - 1
        dt.setMonth(nextMonth)
      }
      return list
    },
  },
  async created() {
    this.isLoadingKPIs = true
    this.isLoadingMetrics = true
    this.isLoadingGoals = true

    this.userService = new UserService()
    this.dashboardService = new DashboardService()
    this.localStorageService = new LocalStorageService()
    this.settingService = new SettingService()

    this.goalsConfig.sales_sdr = this.goalsConfig.sales
    this.metricsFilters.sales_sdr = this.metricsFilters.sales

    const userDataString = await localStorage.getItem('userData')
    this.userData = await JSON.parse(userDataString)
    this.managers = await this.userService.getAndStoreManagers()

    const today = new Date()
    const month = today.getMonth() + 1
    this.monthSelected = `${today.getFullYear()}-${month < 10 ? `0${month}` : month}-01`

    let dateWithoutRotine = await this.settingService.getByKey('management_processes_days_without_routine')
    if (dateWithoutRotine.length === 10) {
      dateWithoutRotine = new Date(dateWithoutRotine)
      this.daysWithoutRotine = Math.round((today.getTime() - dateWithoutRotine.getTime()) / (1000 * 3600 * 24))
    }

    // set date to calendars
    let from = new Date((new Date()).setDate(1))
    from = `${from.toISOString().substr(0, 10)} 00:00:00 `
    let to = `${today.toISOString().substr(0, 10)} 23:59:59`
    this.metricsPeriod = `${from} até ${to}`

    from = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
    from = `${from.toISOString().substr(0, 10)} 00:00:00 `
    to = `${today.toISOString().substr(0, 10)} 23:59:59`
    this.graphicsPeriod = `${from} até ${to}`

    from = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7 - today.getDay())
    to = new Date(from.getFullYear(), from.getMonth(), from.getDate() + 6)
    from = `${from.toISOString().substr(0, 10)} 00:00:00 `
    to = `${to.toISOString().substr(0, 10)} 00:00:00`
    this.KPIsPeriod = `${from} até ${to}`

    if (this.userData.permission_type === 'root' || this.userData.permission_type === 'manager') {
      this.permissionType = { role: 'root', id: null }
    } else {
      this.permissionType = { role: this.userData.permission_type, id: this.userData.id }
    }

    this.setRoles()
    this.changeUser()
  },
  methods: {
    showWelcomeModal() {
      this.$refs.dashboardWelcome.showModal()
    },
    showUserTypeInMetrics() {
      return this.userData.permission_type === 'root' && this.permissionType.role === 'root'
    },
    colsMetrics() {
      // valida os retornos da API com os campos que devem ser exibidos, e decide a quantidade de colunas a ser usada
      const { length } = Object.entries(this.metricsData).map(m => this.metricsFilters[this.permissionType.role].find(f => f.value === m[0]))
      if (length >= 5 && length % 5 === 0) {
        return 2
      }

      if (length >= 4 && length % 4 === 0) {
        return 3
      }

      if (length >= 3 && length % 3 === 0) {
        return 4
      }

      return 3
    },
    downloadReport() {
      this.isDownloading = true
      const current = new Date()
      const month = this.months[current.getMonth() === 0 ? 11 : current.getMonth() - 1].text
      const manager = this.managers.find(f => (f.id === this.permissionType.id))
      return this.dashboardService.downloadReport(this.permissionType.id, this.permissionType.role).then(response => {
        this.isDownloading = false
        this.openModalToSaveFile(response, `Relatório ${manager.name} - ${month}.pdf`)
      })
    },
    setRoles() {
      if (this.userData.permission_type === 'root') {
        // monta o array com todas as áreas + todos usuários de cada área para exibir no select box
        this.roles = [
          {
            label: 'Root',
            options: [
              {
                value: { role: 'root', id: null },
                text: 'Dashboard',
              },
            ],
          },
          ...userRoles.map(role => (
            {
              label: role.text,
              options: [
                {
                  value: { role: role.value, id: null },
                  text: `Todos - ${role.text}`,
                },
                ...this.managers.filter(f => (f.permission_type === role.value && !f.deleted_at)).map(manager => ({
                  value: { role: role.value, id: manager.id },
                  text: manager.name,
                })),
              ],
            }
          )),
        ]
      } else {
        const role = userRoles.find(r => r.value === this.permissionType.role)
        this.roles = [
          {
            value: { role: role.value, id: null },
            text: `Todos - ${role.text}`,
          },
          {
            value: { role: this.permissionType.role, id: this.permissionType.id },
            text: this.managers.find(manager => manager.id === this.permissionType.id).name,
          },
        ]
      }
    },

    metricsWithUsersList() {
      this.metricsPressed = 'user'

      this.$swal({
        title: 'Métricas por usuários',
        text: 'Inserir o ID dos usuários separados por vírgula',
        input: 'text',
        inputValue: this.metricsByUsers,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Pesquisar',
        showLoaderOnConfirm: true,
      }).then(result => {
        if (result.isConfirmed) {
          this.metricsByUsers = result.value
          this.getMetrics()
        }
      })
    },

    onClickPeriodMetrics(value) {
      this.metricsPressed = value
      const today = new Date()
      let from = null
      let to = null

      switch (value) {
        default:
          break

        case 'lastMonth':
          // mes anterior
          from = new Date(today.getFullYear(), today.getMonth() - 1, 1)
          from = `${from.toISOString().substr(0, 10)} 00:00:00 `
          to = new Date(today.getFullYear(), today.getMonth(), 0)
          to = `${to.toISOString().substr(0, 10)} 00:00:00`
          break

        case 'currentMonth':
          // mes atual
          from = new Date(today.getFullYear(), today.getMonth(), 1)
          from = `${from.toISOString().substr(0, 10)} 00:00:00 `
          to = `${today.toISOString().substr(0, 10)} 00:00:00`
          break

        case 'month':
          // -30
          from = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
          from = `${from.toISOString().substr(0, 10)} 00:00:00 `
          to = `${today.toISOString().substr(0, 10)} 00:00:00`
          break

        case 'week':
          // semana atual
          from = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
          from = `${from.toISOString().substr(0, 10)} 00:00:00 `
          to = `${today.toISOString().substr(0, 10)} 00:00:00`
          break

        case 'yesterday':
          // ontem
          from = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
          to = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
          from = `${from.toISOString().substr(0, 10)} 00:00:00 `
          to = `${to.toISOString().substr(0, 10)} 00:00:00`
          break

        case 'day':
          from = `${today.toISOString().substr(0, 10)} 00:00:00 `
          to = `${today.toISOString().substr(0, 10)} 00:00:00 `
          break
      }

      this.metricsPeriod = `${from} até ${to}`
      this.getMetrics()
    },
    formatValue(value, type = 'decimal') {
      if (type === 'currency') {
        return this.$options.filters.currency(value)
      }

      if (type === 'percent') {
        return `${this.$options.filters.decimal(value)}%`
      }

      if (type === 'decimal') {
        return this.$options.filters.decimal(value)
      }

      return value
    },
    getValueOfMetricFilters(index, value, def) {
      const ret = def === undefined ? '' : def
      if (this.metricsFilters[this.permissionType.role]) {
        const filter = this.metricsFilters[this.permissionType.role].find(f => f.value === index)
        if (filter && typeof filter === 'object' && filter[value]) {
          return filter[value]
        }
      }
      return ret
    },
    onCloseCalendar(func) {
      // foi necessário porque o calendário retorna somente a primeira data selecionada antes do evento on-close
      this.$nextTick(() => {
        func()
      })
    },
    changeUser() {
      this.selectedGraphic = this.metricsFilters[this.permissionType.role][0].value
      this.filtersGraphic = this.metricsFilters[this.permissionType.role].filter(f => f.graphic)

      const today = new Date()
      let dateFrom = null
      if (this.permissionType.role !== 'root') {
        dateFrom = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
        dateFrom = `${dateFrom.toISOString().substr(0, 10)} 00:00:00 `

        this.getGoals()
        this.getKPIs()
      } else {
        dateFrom = new Date(today.getFullYear() - 1, today.getMonth(), 1)
        dateFrom = `${dateFrom.toISOString().substr(0, 10)} 00:00:00 `
      }

      const dateTo = `${today.toISOString().substr(0, 10)} 23:59:59`
      this.graphicsPeriod = `${dateFrom} até ${dateTo}`

      this.getMetrics()
      if (this.showGraphics) {
        this.getGraphics()
      }
    },
    async getGoals() {
      this.isLoadingGoals = true

      this.dashboardService.goalsV2(this.permissionType.role, this.permissionType.id, this.monthSelected).then(response => {
        this.isLoadingGoals = false
        this.goalsData = Object.fromEntries(Object.entries(response.data.data.goals).filter(f => f[0].indexOf('debug') === -1))
      })
    },
    loadGraphicFromSelectedMetric() {
      const labels = []
      const series = []
      this.data.revenueReport.series = [
        {
          name: 'Total',
          data: [],
        },
      ]
      this.revenueReport.chartOptions.xaxis.categories = []

      let graphicDirectionHorizontal = false
      if (this.permissionType.role === 'root' && !this.selectedGraphic?.startsWith('daily_')) {
        graphicDirectionHorizontal = true
      }

      if (this.graphicsData[this.selectedGraphic]) {
        this.graphicsData[this.selectedGraphic].forEach(item => {
          series.push(item.value)
          if (graphicDirectionHorizontal) {
            const dt = new Date(`${item.date} 00:00:00`)
            labels.push(`${this.months[dt.getMonth()].text.substring(0, 3)} ${dt.getFullYear().toString().substring(2, 4)}`)
          } else {
            const dt = new Date(`${item.date} 00:00:00`)
            labels.push(`${dt.getDate() < 10 ? '0' : ''}${dt.getDate()}/${dt.getMonth() + 1 < 10 ? '0' : ''}${dt.getMonth() + 1}`)
          }
        })
      }

      let dataLabels = null
      let plotOptions = null
      if (graphicDirectionHorizontal) {
        dataLabels = {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#6E6B7B'],
          },
          offsetX: 25,
        }

        plotOptions = {
          bar: {
            horizontal: true,
            columnWidth: '17%',
            endingShape: 'rounded',
            dataLabels: {
              position: 'top',
            },
          },
          distributed: true,
        }
      } else {
        dataLabels = {
          enabled: false,
          offsetX: 25,
        }

        plotOptions = {
          bar: {
            horizontal: false,
            columnWidth: '17%',
            endingShape: 'rounded',
          },
          distributed: true,
        }
      }

      this.data.revenueReport.series = [
        {
          name: 'Total',
          data: series,
        },
      ]
      // devemos atualizar o objeto chartOptions inteiro para que os labels sejam recarregados
      this.revenueReport.chartOptions = {
        ...this.revenueReport.chartOptions,
        ...{
          dataLabels,
          plotOptions,
          xaxis: {
            ...this.revenueReport.chartOptions.xaxis,
            ...{
              categories: labels,
            },
          },
        },
      }
    },
    async getGraphics() {
      this.showGraphics = true
      this.isLoadingGraphics = true

      const dateFrom = `${this.graphicsPeriod.split(' até ')[0].substring(0, 10)} 00:00:00`
      const dateTo = `${this.graphicsPeriod.split(' até ')[1].substring(0, 10)} 23:59:59`

      const type = this.permissionType.role === 'root' ? this.selectedType : ''

      this.dashboardService.metricsForGraphic(this.permissionType.role, this.permissionType.id, dateFrom, dateTo, type).then(response => {
        this.isLoadingGraphics = false
        this.graphicsData = {
          ...this.graphicsData,
          ...response.data.data.metrics,
        }
        this.loadGraphicFromSelectedMetric()
      })
    },
    async getMetrics() {
      this.isLoadingMetrics = true

      let dateFrom = `${this.metricsPeriod.substring(0, 10)} 00:00:00`
      let dateTo = `${this.metricsPeriod.substring(0, 10)} 23:59:59`
      const type = this.permissionType.role === 'root' ? this.selectedType : ''

      if (this.metricsPeriod.includes(' até ')) {
        dateFrom = `${this.metricsPeriod.split(' até ')[0].substring(0, 10)} 00:00:00`
        dateTo = `${this.metricsPeriod.split(' até ')[1].substring(0, 10)} 23:59:59`
      }

      this.dashboardService.metrics(this.permissionType.role, this.permissionType.id, dateFrom, dateTo, type, this.metricsByUsers).then(response => {
        this.isLoadingMetrics = false
        this.metricsData = Object.fromEntries(Object.entries(response.data.data.metrics).filter(f => f[0].indexOf('debug') === -1 && f[0].indexOf('_tooltip') === -1))
        this.metricsDataToolTip = Object.fromEntries(Object.entries(response.data.data.metrics).filter(f => f[0].indexOf('_tooltip') !== 0))
      })
    },
    async getKPIs() {
      this.isLoadingKPIs = true
      let dateFrom = `${this.KPIsPeriod.substring(0, 10)} 00:00:00`
      let dateTo = `${this.KPIsPeriod.substring(0, 10)} 23:59:59`
      if (this.KPIsPeriod.includes(' até ')) {
        dateFrom = `${this.KPIsPeriod.split(' até ')[0].substring(0, 10)} 00:00:00`
        dateTo = `${this.KPIsPeriod.split(' até ')[1].substring(0, 10)} 23:59:59`
      }

      this.dashboardService.kpis(this.permissionType.role, this.permissionType.id, dateFrom, dateTo).then(response => {
        this.isLoadingKPIs = false
        this.KPIsData = response.data.data.kpis
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/pages/page-misc.scss';
</style>
