/* eslint-disable class-methods-use-this, import/no-extraneous-dependencies */

import useJwt from '@/auth/jwt/useJwt'

export default class SettingService {
  async getByKey(key) {
    return useJwt.get(`/setting/${key}`).then(response => {
      if (response && response.data.data.setting) {
        return response.data.data.setting
      }

      return 0
    })
  }
}
