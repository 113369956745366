<template>
  <section id="dashboard">
    <h5 class="text-center">
      {{ title }}
      <feather-icon
        v-if="debug"
        v-b-tooltip.hover.html="tooltipText"
        data-bs-html="true"
        icon="HelpCircleIcon"
      />
    </h5>
    <b-row
      v-if="isLoading"
      class="mt-1 mb-1"
    >
      <b-col>
        <div class="text-center">
          <b-spinner /><br>
          <span class="ml-1">Carregando...</span>
        </div>
      </b-col>
    </b-row>
    <div v-if="!isLoading">
      <vue-apex-charts
        type="radialBar"
        height="195"
        class="my-2"
        :options="goalOverviewRadialBar"
        :series="calcReached()"
      />
    </div>

    <b-row class="text-center mx-0">
      <b-col
        cols="6"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          Alcançado
        </b-card-text>
        <h4 class="font-weight-bolder mb-0 mt-1">
          {{ filterValue(completed) }}
        </h4>
      </b-col>

      <b-col
        cols="6"
        class="border-top d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          Meta
        </b-card-text>
        <h4 class="font-weight-bolder mb-0 mt-1">
          {{ filterValue(goal) }}
        </h4>
      </b-col>
    </b-row>

  </section>
</template>

<script>

/* eslint-disable global-require */
import {
  BCardText,
  BSpinner,
  BRow,
  BCol,
  VBTooltip,
} from 'bootstrap-vue'
  // import VuexyLogo from '@core/layouts/components/Logo.vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#51e5a8'

export default {
  components: {
    VueApexCharts,
    BSpinner,
    BCardText,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    title: {
      type: String,
      default: 'Título',
    },
    goal: {
      type: Number,
      default: 0,
    },
    completed: {
      type: Number,
      default: 0,
    },
    decimalFormat: {
      type: String,
      default: 'currency', // currency | decimal
    },
    debug: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      userService: null,
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Atendimento e Qualificação',
          active: true,
        },
      ],
      goalOverview: {
        completed: 'R$13,56',
        goal: 'R$786,61',
        series: [20],
      },
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: '2.86rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
    }
  },
  computed: {
    tooltipText() {
      const c = Object.entries(this.debug).reduce((carry, item) => `${carry}${item[0]}: ${item[1]}<BR />`, '')
      return `<p class="tooltip-dashboard">${c}</p>`
    },
  },
  methods: {
    filterValue(value) {
      if (this.decimalFormat === 'currency') {
        return this.$options.filters.currency(value)
      }

      if (this.decimalFormat === 'decimal') {
        return this.$options.filters.decimal(value)
      }

      if (this.decimalFormat === 'percent') {
        return `${this.$options.filters.decimal(value)}%`
      }

      if (this.decimalFormat === 'percent.decimal') {
        return `${parseInt(value, 10)}%`
      }

      return value
    },
    calcReached() {
      if (this.completed === 0 || this.goal === 0) {
        return [0]
      }

      return [Math.round((this.completed / this.goal) * 100, 10)]
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';

  .tooltip-inner{
    width: 300px!important;
    max-width: 300px!important;
  }

  .tooltip-dashboard{
    width: 280px!important;
  }
</style>
