<template>
  <b-card class="text-center">
    <div style="position: absolute; left: 55%; margin-top: -20px">
      <b-button
        v-if="tooltipText !== ''"
        v-b-tooltip.hover.html="tooltipText"
        variant="flat-secondary"
        class="btn-icon"
      >
        <feather-icon
          icon="InfoIcon"
          size="16"
        />
      </b-button>
    </div>

    <b-avatar
      class="mb-1"
      :variant="`light-${color}`"
      size="45"
    >
      <feather-icon
        v-if="icon !== ''"
        size="21"
        :icon="icon"
      />
      <Iconify
        v-if="iconify"
        :icon="iconify"
        width="21"
        height="21"
      />
    </b-avatar>
    <div class="truncate">
      <h2 class="mb-25 font-weight-bolder">
        {{ statistic }}
      </h2>
      <span>{{ statisticTitle }}</span>
    </div>
  </b-card>
</template>

<script>
import { BCard, BAvatar, VBTooltip, BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCard,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    tooltipText: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    iconify: {
      type: Object,
      required: false,
      default: () => {},
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
