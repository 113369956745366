<template>
  <section id="dashboard-proxy">
    <dashboard-v2 />
  </section>
</template>

<script>
/* eslint-disable global-require */
import {
} from 'bootstrap-vue'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import DashboardV2 from './DashboardV2.vue'

export default {
  components: {
    DashboardV2,
  },
  data() {
    return {
      userData: {
        permission_type: '',
      },
    }
  },
  computed: {
  },
  async created() {
    const userDataString = await localStorage.getItem('userData')
    this.userData = await JSON.parse(userDataString)
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
